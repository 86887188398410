import styled from '@emotion/styled';
import { COLORS } from '@sortlist-frontend/design-system';

export const SectionTitle = styled.div`
  &:hover,
  &.active {
    background-color: ${COLORS.PRIMARY_100};

    h6,
    p {
      color: ${COLORS.PRIMARY_500};
    }

    .arrow-forward {
      display: block !important;
    }
  }
`;
