import { useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';
import { FC } from 'react';

import { BriefingButton } from '_components/Briefing/versions/NormalBriefing/button';
import { usePublicAppContext } from '_core/context/public-app-context';
import { useExpertises } from '_core/repos/expertises.repo';

import { ExpertiseList } from './ExpertiseList';
import * as S from './styles';

type Props = {
  className: string;
};
export const PostAProject: FC<Props> = (props) => {
  const { className } = props;
  const { getUrl, briefingOptions } = usePublicAppContext();
  const { data: expertises, isLoading } = useExpertises();
  const { t } = useTranslation(['common']);

  const sortedExpertises = expertises?.sort((a, b) => a.name.localeCompare(b.name));

  const firstExpertises = sortedExpertises?.slice(0, (sortedExpertises?.length + 1) / 2);
  const secondExpertises = sortedExpertises?.slice(13, sortedExpertises?.length + 1);

  const newProject = getUrl('project.new');

  return (
    <div className={clsx(className, 'layout-row')}>
      <div className="flex-45 layout-column px-16">
        <div className="py-16 px-8 flex layout-column">
          <p className="h6 bold">{t('common:navbar.findAgencies.postAProject.letAgenciesComeToYou_V2')}</p>
          <p className="small text-secondary-500 mt-8">
            {t('common:navbar.findAgencies.postAProject.describeYourNeeds_V2')}
          </p>
          <ul className="small text-secondary-500 mt-16 pl-24 mb-0">
            <li className="pb-8">{t('common:navbar.findAgencies.postAProject.simpleQuickAgile')}</li>
            <li className="pb-8">{t('common:navbar.findAgencies.postAProject.completeAndUpToDate')}</li>
            <li className="pb-8">{t('common:navbar.findAgencies.postAProject.personalAssistance')}</li>
          </ul>
          <a target="_blank" href={newProject} className="mt-8 text-underline small text-secondary-500">
            {t('common:navbar.findAgencies.postAProject.learnMore')}
          </a>
        </div>
        <div className="py-16 px-8">
          <BriefingButton
            page={briefingOptions?.page}
            cta="navbar_project"
            dataTestid="briefing-button-navbar-project"
            size={'sm'}
            buttonStyle="primary"
            buttonVariant="raised"
            className={className}
            label={t('common:navbar.menu.postAProject')}
            truncate={false}
          />
        </div>
      </div>
      {!isLoading && expertises && expertises?.length > 0 && (
        <div className="flex layout-column px-16">
          <S.NavbarExpertiseList
            data-testid={`navbar-expertise-list`}
            className="bg-secondary-100 rounded-md layout-column px-8 flex-grow">
            <div className="px-16 pt-16">
              <p className="h6 bold text-secondary-900">
                {t('common:navbar.findAgencies.postAProject.postAProjectIn')}
              </p>
            </div>
            <div className="px-8 pb-8 flex layout-column layout-align-start-stretch">
              <div className="layout-row mt-8">
                <div className="px-8 flex">
                  <ExpertiseList expertises={firstExpertises} />
                </div>
                <div className="px-8 flex">
                  <ExpertiseList expertises={secondExpertises} />
                </div>
              </div>
            </div>
          </S.NavbarExpertiseList>
        </div>
      )}
    </div>
  );
};
