import { AccountCircleTwoTone } from '@mui/icons-material';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { FC, useState } from 'react';

import { usePublicAppContext } from '_core/context/public-app-context';

import { MenuDropdown, MenuDropdownProps } from '../MenuDropdown';
import { getAccountMenuData } from '../utils';

const Dropdown = dynamic<MenuDropdownProps>(() => import('../MenuDropdown').then((mod) => mod.MenuDropdown), {
  ssr: false,
});

type AccountMenuProps = {
  className?: string;
};

export const AccountMenu: FC<AccountMenuProps> = (props) => {
  const { className } = { ...props };
  const { t } = useTranslation(['common']);
  const { getUrl } = usePublicAppContext();
  const links = getAccountMenuData(t, getUrl);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenAccountMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    setAnchorEl(event.currentTarget);
  };

  const handleCloseAccountMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button
        className={clsx(
          className,
          'cursor-pointer border-none bg-neutral-100 layout-row layout-align-center-center p-0',
        )}
        id="default-menu-btn"
        data-testid="navbar-login-button"
        onClick={handleOpenAccountMenu}>
        <AccountCircleTwoTone className="text-secondary-500" style={{ fontSize: 20 }} />
      </button>
      <MenuDropdown links={links} handleCloseMenu={handleCloseAccountMenu} anchorEl={anchorEl} />
    </>
  );
};
