import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material';
import { Button } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';
import React, { FC, Fragment, MouseEvent, useState } from 'react';

import { usePublicAppContext } from '_core/context/public-app-context';

import { MenuDropdown } from '../MenuDropdown';
import { getResourcesMenuData } from '../utils';

type GetResourcesMenuProps = {
  handleMenuOpen: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

export const ResourcesMenu: FC<GetResourcesMenuProps> = (props: GetResourcesMenuProps) => {
  const { handleMenuOpen, className } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation(['common']);
  const { domainInfo, locale, getUrl } = usePublicAppContext();
  const links = getResourcesMenuData(t, getUrl, domainInfo, locale);

  const handleOpenAccountMenu = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    handleMenuOpen(event);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAccountMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        data-testid="navbar-resources-dropdown"
        size="sm"
        iconSize="lg"
        className={clsx(className, 'small px-4')}
        onClick={handleOpenAccountMenu}
        label={t('common:navbar.menu.resources')}
        buttonVariant="default"
        buttonStyle="secondary"
        animation="none"
        iconRight={
          anchorEl != null ? (
            <ArrowDropUpRounded className="text-secondary-300" />
          ) : (
            <ArrowDropDownRounded className="text-secondary-300" />
          )
        }
        id="navbar-resources-dropdown"
      />
      {anchorEl != null ? (
        <MenuDropdown handleCloseMenu={handleCloseAccountMenu} anchorEl={anchorEl} links={links} />
      ) : null}
    </>
  );
};
