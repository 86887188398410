import { TFunction } from '@sortlist-frontend/translation/ssr';
import { splitArrayIntoTwoEqualParts } from '@sortlist-frontend/utils';

import type { Levels } from '_core/utils/public-links/_types';
import { getAttributes } from '_core/utils/public-links/get-attributes';
import { HierarchyInnerLevelType, HierarchyType, NewAnchorType } from '_features/home/types';
import type { Link, Links } from '_types/public-api';

export const createSortedLvlAfterPosition = (obj: Levels) => {
  const newArr = Object.keys(obj).map((value) => {
    return { [value]: obj[value]['null']['null'][0].position };
  });

  return newArr?.sort((a, b) => {
    return a[Object.keys(a)[0]] - b[Object.keys(b)[0]];
  });
};

export const sortByPosition = (hierarchyLinks: HierarchyType[]) => {
  hierarchyLinks.forEach((levelOne) => {
    let links = levelOne.links;
    if (links) {
      links = links.sort((a, b) => a.position - b.position);
    }

    levelOne?.children?.forEach((levelTwo) => {
      let links = levelTwo.links;

      if (links) {
        links = links.sort((a, b) => a.position - b.position);
      }

      levelTwo.children?.forEach((levelThree) => {
        let links = levelThree?.links;

        if (links) {
          links = links.sort((a, b) => a.position - b.position);
        }
      });
    });
  });

  return hierarchyLinks;
};

export const sortLevelsByFirstLink = (hierarchyLinks: HierarchyType[]) => {
  hierarchyLinks.forEach((levelOne) => {
    levelOne?.children?.forEach((levelTwo) => {
      levelTwo.children = levelTwo.children?.sort((a, b) => {
        const aLinks = a.links;
        const bLinks = b.links;

        return aLinks[0].position - bLinks[0].position;
      });
    });

    if (levelOne.children) {
      levelOne.children = levelOne?.children?.sort((a, b) => {
        const aLinks = a.links || a.children[0].links;
        const bLinks = b.links || b.children[0].links;

        return aLinks && bLinks ? aLinks[0].position - bLinks[0].position : 0;
      });
    }
  });

  return hierarchyLinks.sort((a, b) => {
    const aLinks = a.links || a.children[0].links || a.children[0].children[0].links;
    const bLinks = b.links || b.children[0].links || b.children[0].children[0].links;

    return aLinks[0].position - bLinks[0].position;
  });
};

const checkAndPopulateLevelOne = (hierarchyLinks: HierarchyType[], levelOne: string, linkObject: NewAnchorType) => {
  // Check if we have Level 1 added
  const index = hierarchyLinks.findIndex((item) => item.name === levelOne);
  if (index === -1)
    hierarchyLinks.push({ name: levelOne, children: [], iconName: linkObject.iconName } as unknown as HierarchyType);

  // Get the actual index
  const newIndex = hierarchyLinks.findIndex((item) => item.name === levelOne);
  return hierarchyLinks[newIndex];
};

const checkAndPopulateLevelTwo = (levelOneHierarchy: HierarchyType, levelTwo: string) => {
  // Check if we have Level 2 added
  const levelTwoIndex = levelOneHierarchy.children?.findIndex((item) => item.name === levelTwo);
  if (levelTwoIndex === -1)
    levelOneHierarchy.children?.push({ name: levelTwo, children: [] } as unknown as HierarchyType);

  // Get the actual levelTwo index
  const newLevelTwoIndex = levelOneHierarchy.children?.findIndex((item) => item.name === levelTwo) as number;
  return levelOneHierarchy?.children && levelOneHierarchy?.children[newLevelTwoIndex];
};

const checkAndPopulateOthers = (
  currentLevel: HierarchyType | HierarchyInnerLevelType,
  linkObject: NewAnchorType,
  t: TFunction,
) => {
  // Check if we have Others
  const othersIndex = currentLevel.children?.findIndex((item) => item.name === 'Others');
  if (othersIndex === -1)
    currentLevel.children?.push({
      name: 'Others',
      translation: t('home:sections.catalog.others_v1'),
      links: [],
    } as unknown as HierarchyType);

  // Get the actual Others index
  const actualOthersIndex = currentLevel.children?.findIndex((item) => item.name === 'Others') as number;
  const object = currentLevel?.children && currentLevel?.children[actualOthersIndex];
  object?.links?.push(linkObject);
};

const handleMaxDepthThree = (
  currentDepth: number,
  hierarchyLinks: HierarchyType[],
  linkObject: NewAnchorType,
  levelOne: string,
  levelTwo: string,
  levelThree: string,
  t: TFunction,
) => {
  if (currentDepth === 1) {
    const levelOneHierarchy = checkAndPopulateLevelOne(hierarchyLinks, levelOne, linkObject);
    checkAndPopulateOthers(levelOneHierarchy, linkObject, t);
  }

  if (currentDepth === 2) {
    const levelOneHierarchy = checkAndPopulateLevelOne(hierarchyLinks, levelOne, linkObject);
    const levelTwoHierarchy = checkAndPopulateLevelTwo(levelOneHierarchy, levelTwo);

    checkAndPopulateOthers(levelTwoHierarchy, linkObject, t);
  }

  if (currentDepth === 3) {
    const levelOneHierarchy = checkAndPopulateLevelOne(hierarchyLinks, levelOne, linkObject);
    const levelTwoHierarchy = checkAndPopulateLevelTwo(levelOneHierarchy, levelTwo);

    // Check if we have Level 2 added
    const levelThreeIndex = levelTwoHierarchy.children?.findIndex((item) => item.name === levelThree);
    if (levelThreeIndex === -1) levelTwoHierarchy.children?.push({ name: levelThree, links: [] });

    // Get the actual levelTwo index
    const newLevelThreeIndex = levelTwoHierarchy.children?.findIndex((item) => item.name === levelThree) as number;

    const object = levelTwoHierarchy.children[newLevelThreeIndex];

    object.links.push(linkObject);
  }
};

const handleMaxDepthTwo = (
  currentDepth: number,
  hierarchyLinks: HierarchyType[],
  linkObject: NewAnchorType,
  levelOne: string,
  levelTwo: string,
  t: TFunction,
) => {
  if (currentDepth === 1) {
    const levelOneHierarchy = checkAndPopulateLevelOne(hierarchyLinks, levelOne, linkObject);
    checkAndPopulateOthers(levelOneHierarchy, linkObject, t);
  }

  if (currentDepth === 2) {
    const levelOneHierarchy = checkAndPopulateLevelOne(hierarchyLinks, levelOne, linkObject);

    // Check if we have Level 2 added
    const levelTwoIndex = levelOneHierarchy.children?.findIndex((item) => item.name === levelTwo);
    if (levelTwoIndex === -1)
      levelOneHierarchy.children?.push({ name: levelTwo, links: [] } as unknown as HierarchyType);

    // Get the actual levelTwo index
    const newLevelTwoIndex = levelOneHierarchy.children?.findIndex((item) => item.name === levelTwo) as number;
    const object = levelOneHierarchy.children[newLevelTwoIndex];
    object.links.push(linkObject);
  }
};

const handleMaxDepthOne = (hierarchyLinks: HierarchyType[], linkObject: NewAnchorType, levelOne: string) => {
  // Check if we have Level 1 added
  const index = hierarchyLinks.findIndex((item) => item.name === levelOne);
  if (index === -1)
    hierarchyLinks.push({ name: levelOne, links: [], iconName: linkObject.iconName } as unknown as HierarchyType);

  // Get the actual index
  const newIndex = hierarchyLinks.findIndex((item) => item.name === levelOne);

  const object = hierarchyLinks[newIndex];
  object.links.push(linkObject);
};

const handleDepthZero = (hierarchyLinks: HierarchyType[], linkObject: NewAnchorType, t: TFunction) => {
  const othersLinksIndex = hierarchyLinks.findIndex((item) => item.name === 'Others');
  if (othersLinksIndex === -1)
    hierarchyLinks.push({
      name: 'Others',
      translation: t('home:sections.catalog.others_v1'),
    } as unknown as HierarchyType);

  const othersLinksIndexUpdated = hierarchyLinks.findIndex((item) => item.name === 'Others');
  const othersElement = hierarchyLinks[othersLinksIndexUpdated];
  if (!othersElement.links) othersElement.links = [];

  othersElement.links.push(linkObject);
};

const getMaxLevel = (links: Link[], levelOne: string) => {
  let maxDepthLevelOfCurrentHierarchy = 1;
  links?.forEach((currentLink) => {
    const hierarchy = currentLink.attributes.metadata?.hierarchy;

    if (hierarchy?.includes(levelOne) && maxDepthLevelOfCurrentHierarchy < hierarchy.length) {
      maxDepthLevelOfCurrentHierarchy = hierarchy.length;
    }
  });
  return maxDepthLevelOfCurrentHierarchy;
};

const returnSortedHierarchy = (hierarchyLinks: HierarchyType[]) => {
  // 1. Sort links by positon prop
  // 2. Sort all levels by the first link position in the list
  const sorted = sortLevelsByFirstLink(sortByPosition(hierarchyLinks));

  // Move Others levelOne links to the end
  const levelOneOthersLinksIndex = hierarchyLinks.findIndex((item) => item.name === 'Others');
  if (levelOneOthersLinksIndex > -1) sorted.push(sorted.splice(levelOneOthersLinksIndex, 1)[0]);

  // Move Others levelTwo links to the end
  hierarchyLinks.map((hierarchy) => {
    const othersLinksIndex = hierarchy.children?.findIndex((item) => item.name === 'Others');
    if (othersLinksIndex > -1) hierarchy.children.push(hierarchy.children.splice(othersLinksIndex, 1)[0]);

    hierarchy.children?.map((innerHierarchy) => {
      const othersLinksIndex = innerHierarchy?.children?.findIndex((item) => item.name === 'Others');
      if (othersLinksIndex > -1) innerHierarchy?.children?.push(innerHierarchy.children.splice(othersLinksIndex, 1)[0]);
    });

    return hierarchy;
  });

  return sorted;
};

export const getHierarchyLinks = (allLinks: Links, t: TFunction, category = 'popular_services') => {
  const links = allLinks?.data.filter((link) => {
    return link.attributes.category === category;
  });

  const hierarchyLinks: HierarchyType[] = [];

  links?.forEach((link) => {
    const { metadata, anchor, position } = link.attributes;
    const { hierarchy, decorations } = metadata || {};
    const pageId = link?.relationships.to_page.data.id;

    const linkObject = {
      anchor,
      iconName: decorations?.icon_name,
      tag: decorations?.tag,
      position,
      link: getAttributes(allLinks, pageId)?.path || '',
      host: getAttributes(allLinks, pageId)?.host || '',
      locale: getAttributes(allLinks, pageId)?.locale || '',
    };

    if (!hierarchy?.length) {
      handleDepthZero(hierarchyLinks, linkObject, t);
    } else {
      const [levelOne, levelTwo, levelThree] = hierarchy;

      const maxDepthLevelOfCurrentHierarchy = getMaxLevel(links, levelOne);

      const currentDepth = hierarchy.length;

      // When current depth is 3
      if (maxDepthLevelOfCurrentHierarchy === 3) {
        handleMaxDepthThree(currentDepth, hierarchyLinks, linkObject, levelOne, levelTwo, levelThree, t);
      }

      // When current depth is 2
      if (maxDepthLevelOfCurrentHierarchy === 2) {
        handleMaxDepthTwo(currentDepth, hierarchyLinks, linkObject, levelOne, levelTwo, t);
      }

      // When current depth is 1
      if (maxDepthLevelOfCurrentHierarchy === 1 && currentDepth === 1) {
        handleMaxDepthOne(hierarchyLinks, linkObject, levelOne);
      }
    }
  });

  return returnSortedHierarchy(hierarchyLinks);
};

export const createColumns = (services?: NewAnchorType[]): NewAnchorType[][] => {
  if (!services) return [];

  const columns = [];

  if (services.length <= 10) {
    columns.push(services);
  } else {
    const [serviceColumn1, serviceColumn2] = splitArrayIntoTwoEqualParts(services) as unknown as NewAnchorType[][];
    columns.push(serviceColumn1);
    columns.push(serviceColumn2);
  }

  return columns;
};
