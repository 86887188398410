import styled from '@emotion/styled';
import { BREAKPOINTS } from '@sortlist-frontend/design-system';

import { NAVBAR_HEIGHT, NAVBAR_XS_HEIGHT } from '../constants';

export const Navbar = styled.header`
  z-index: 96;
  height: ${NAVBAR_HEIGHT};

  @media only screen and (max-width: ${BREAKPOINTS.sm}) {
    height: ${NAVBAR_XS_HEIGHT};
  }
`;
