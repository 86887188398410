import { ImageSourceHelper } from '@sortlist-frontend/media';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';
import Image from 'next/image';
import { FC } from 'react';

import { BriefingButton } from '_components/Briefing/versions/NormalBriefing/button';
import { usePublicAppContext } from '_core/context/public-app-context';

type Props = {
  className: string;
};

const s3BucketUrl = process.env.NEXT_PUBLIC_S3_BUCKET;

export const GetAdvice: FC<Props> = (props) => {
  const { className } = props;
  const { t } = useTranslation(['common']);
  const { getUrl } = usePublicAppContext();

  return (
    <div className={clsx(className, 'layout-row')}>
      <div className="flex-45 layout-column px-8">
        <div className="p-16 flex layout-column">
          <p className="h6 bold">{t('common:navbar.findAgencies.getAdvice.ourAdvisorsGuideYou_V2')}</p>
          <p className="mt-8 small text-secondary-500">
            {t('common:navbar.findAgencies.getAdvice.letOurIndustryExperts_V2')}
          </p>
          <a
            data-testid="navbar-advice-learn-more-cta"
            href={getUrl('client.advisors')}
            className="mt-16 display-block small cursor-pointer text-secondary-500 text-underline">
            {t('common:navbar.findAgencies.getAdvice.learnMore')}
          </a>
        </div>
        <div className="p-16">
          <BriefingButton
            data-testid="navbar-advice-cta"
            cta={'navbar_advice'}
            page="advisors"
            label={
              <Trans i18nKey={'common:navbar.findAgencies.getAdvice.buttonLabel'}>
                Get advice <span className="italic">it’s free!</span>.
              </Trans>
            }
            buttonStyle={'primary'}
            buttonVariant={'raised'}
            size="sm"
          />
        </div>
      </div>
      <div className="layout-column layout-align-center-start flex px-8 relative">
        <Image
          src={ImageSourceHelper.fromUrl(`${s3BucketUrl}/illustrations/advisors/advisor-laptop.webp`, { width: 828 })}
          alt={t('common:navbar.findAgencies.getAdvice.imageAlt') as string}
          fill
          style={{ objectFit: 'contain' }}
          onError={() => {}}
        />
      </div>
    </div>
  );
};
