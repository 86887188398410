import { MinimalHeader } from '@sortlist-frontend/design-system';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { CSSProperties, FC, ForwardedRef, ReactElement, ReactNode, useEffect, useState } from 'react';

import { Main } from './Main';
import { PrimaryNav } from './PrimaryNav';
import { ProjectBanner } from './ProjectBanner';
import { isProjectBannerActive } from './utils';

const Footer = dynamic<Record<string, unknown>>(() => import('./Footer').then((mod) => mod.Footer), {
  ssr: true,
});

type LayoutProps = {
  children: ReactNode;
  className?: string;
  scrollableCtn?: ForwardedRef<HTMLDivElement>;
  nav?: boolean;
  showFooter?: boolean;
  showMinimalHeader?: boolean;
  minimalHeaderChildren?: ReactElement;
  showSearch?: boolean;
  showSubnav?: boolean;
  style?: CSSProperties;
};

/**
 * This will help react-lazyload to listen to scrolling position on the content area
 * Change it wisely cause it can break lazy loading in the public-app
 */
export const publicMainScrollableContentCtn = 'public-main-scrollable-content-ctn';

export const PublicLayout: FC<LayoutProps> = (props) => {
  const {
    children,
    className,
    nav,
    minimalHeaderChildren,
    scrollableCtn,
    showFooter,
    showMinimalHeader,
    showSearch,
    showSubnav,
    style,
  } = {
    showFooter: true,
    showSearch: true,
    showMinimalHeader: false,
    nav: true,
    ...props,
  };

  const router = useRouter();

  const [isProjectBannerShown, setIsProjectBannerShown] = useState(false);

  const isDarkHeader = router.pathname === '/project/dashboard/[encodedProjectUuid]';

  // TODO: Uncomment this when project banner is adapted
  // useEffect(() => {
  //   setIsProjectBannerShown(isProjectBannerActive(router.pathname));
  // }, []);

  return (
    <div className={clsx(className, 'layout-column layout-fill')} style={style}>
      {isProjectBannerShown && <ProjectBanner setIsProjectBannerShown={setIsProjectBannerShown} />}

      {nav ? (
        showMinimalHeader ? (
          <MinimalHeader isDark={isDarkHeader} minimalHeaderChildren={minimalHeaderChildren} />
        ) : (
          <PrimaryNav showSearch={showSearch} showSubnav={showSubnav} />
        )
      ) : null}

      <Main scrollableCtn={scrollableCtn} id={publicMainScrollableContentCtn}>
        {children}
        {showFooter && <Footer />}
      </Main>
    </div>
  );
};
