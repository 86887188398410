import { DeepWriteable } from '@sortlist-frontend/utils';

import { config } from '../defaults';

export enum Locale {
  en = 'en',
  fr = 'fr',
  de = 'de',
  nl = 'nl',
  it = 'it',
  es = 'es',
}

type RawAvailableFeatureToggles = DeepWriteable<typeof config>;
export type AvailableFeatureNames = keyof RawAvailableFeatureToggles;

export type AvailableFeatureToggles = {
  [Key in AvailableFeatureNames]: RawAvailableFeatureToggles[Key];
};

export type FlagValueString = `${string}-c` | `${string}-${number}`;

export type FeatureFlagValue = FlagValueString | 'control';

export type Distribution = Record<FeatureFlagValue, number>;
export interface Experiment {
  name: string;
  distribution: Distribution;
}

export interface SimpleFeatureFlag {
  defaultValue: FeatureFlagValue | boolean;
}

export interface FeatureFlagWithVariants {
  defaultValue: FeatureFlagValue | boolean;
  variants: FeatureFlagValue[];
}

export interface FeatureFlagWithExperiments extends FeatureFlagWithVariants {
  experiments: Experiment[];
}

export type Environment = 'staging' | `sandbox-${number}` | 'sandbox' | 'development';

export interface EnabledForConfig {
  /** If you need to enable the feature flag for production, just set the default value to true */
  enabledForEnvironments?: Environment[];
}

// Only support simple feature flag for now
export type FeatureToggleConfig = (SimpleFeatureFlag | FeatureFlagWithVariants | FeatureFlagWithExperiments) &
  EnabledForConfig;

export type MergedFeatureToggles = Record<AvailableFeatureNames, FeatureToggleConfig>;

export interface FeatureFlagSetup {
  configOverride: Partial<MergedFeatureToggles>;
  abTestCookie?: string;
}

export function isFeatureFlagWithVariants(config: FeatureToggleConfig): config is FeatureFlagWithVariants {
  return 'variants' in config;
}

export function isFeatureFlagWithExperiments(config: FeatureToggleConfig): config is FeatureFlagWithExperiments {
  return 'experiments' in config;
}
