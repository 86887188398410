import { TFunction } from '@sortlist-frontend/translation/ssr';

import { DomainInfo } from '_backend/integration/domain-info';
import { getExternalResourcesUrl } from '_components/layout/utils';
import { GetUrlType } from '_core/links/public-app-links';

export type MenuData = {
  href: string;
  visible: boolean;
  label: string;
  dataTestid: string;
};

export const getClientsMenuData = (t: TFunction, getUrl: GetUrlType): MenuData[] => {
  return [
    {
      href: getUrl('providers'),
      visible: true,
      label: t('common:navbar.getClientsMenu.apply_V2'),
      dataTestid: 'navbar-get-clients-apply',
    },
    {
      href: getUrl('providers.pricing'),
      visible: true,
      label: t('common:navbar.getClientsMenu.pricing'),
      dataTestid: 'navbar-get-clients-pricing',
    },
  ];
};

export const getAccountMenuData = (t: TFunction, getUrl: GetUrlType): MenuData[] => {
  return [
    {
      href: getUrl('join'),
      visible: true,
      dataTestid: 'navbar-signup',
      label: t('common:navbar.accountMenu.signup'),
    },
    {
      href: getUrl('selectLoginType'),
      visible: true,
      dataTestid: 'navbar-login',
      label: t('common:navbar.accountMenu.login'),
    },
  ];
};

export const getResourcesMenuData = (
  t: TFunction,
  getUrl: GetUrlType,
  domainInfo: DomainInfo | null,
  locale: string,
): MenuData[] => {
  return [
    {
      href: getExternalResourcesUrl('blog', domainInfo, locale),
      visible: domainInfo?.hasBlog() ? domainInfo.hasBlog() : false,
      label: t('common:navbar.resourcesMenu.blog'),
      dataTestid: 'navbar-resources-blog',
    },
    {
      href: getExternalResourcesUrl('datahub', domainInfo, locale),
      visible: domainInfo?.hasDataHub() ? domainInfo.hasDataHub() : false,
      label: t('common:navbar.resourcesMenu.datahub_v1'),
      dataTestid: 'navbar-resources-datahub',
    },
    {
      href: getUrl('stories'),
      visible: true,
      label: t('common:navbar.resourcesMenu.stories'),
      dataTestid: 'navbar-resources-stories',
    },
    {
      href: `https://help.sortlist.com/${locale}`,
      visible: true,
      label: t('common:navbar.resourcesMenu.help&Support'),
      dataTestid: 'navbar-resources-help',
    },
  ];
};
