import styled from '@emotion/styled';
import { COLORS } from '@sortlist-frontend/design-system';

import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';

export const Anchor = styled(Obfuscate)`
  &:hover {
    color: ${COLORS.PRIMARY_500};
  }
`;
