import { DesktopNav } from './DesktopNav';
import { MobileNav } from './MobileNav';
import * as S from './styles';

type HeaderProps = {
  showSubnav?: boolean;
  showSearch?: boolean;
};

export const PrimaryNav: React.FC<HeaderProps> = (props) => {
  const { showSubnav, showSearch } = props;

  return (
    <S.Navbar className="border-bottom border-secondary-300 bg-neutral-100 sticky">
      <DesktopNav className="hide show-gt-sm" showSearch={showSearch} showSubnav={showSubnav} />
      <MobileNav className="hide-gt-sm px-16" />
    </S.Navbar>
  );
};
