import { getCookie, setCookie } from 'cookies-next';

import { useAbTestCookieValue } from './FeatureFlagsProvider';
import { Distribution, Experiment, FeatureFlagValue, FeatureToggleConfig, isFeatureFlagWithExperiments } from './types';

export const AB_TESTS_COOKIE_NAME = 'ab-tests';
const AB_TESTS_COOKIE_ASSIGNATOR = ':';
const AB_TESTS_COOKIE_SEPARATOR = ',';
const AB_TEST_MAX_AGE = 60 * 60 * 24 * 7; // 1 week

function pickRandom(distribution: Distribution) {
  // Create an array to store the keys
  const keys: FeatureFlagValue[] = [];

  for (const [key, value] of Object.entries(distribution)) {
    for (let i = 0; i < value * 100; i++) {
      keys.push(key as FeatureFlagValue);
    }
  }

  // Return a randomly selected key from the array
  return keys[Math.floor(Math.random() * keys.length)];
}

function getActiveVariantForExperiment(experiment: Experiment): FeatureFlagValue {
  return pickRandom(experiment.distribution);
}

function getVariantFromCookie(experimentName: string, cookiesFromServer?: string): FeatureFlagValue | undefined {
  const experimentsFromCookie = getCookie(AB_TESTS_COOKIE_NAME) ?? cookiesFromServer;
  if (experimentsFromCookie == null) {
    return;
  }
  const savedExperiments = experimentsFromCookie?.toString().split(AB_TESTS_COOKIE_SEPARATOR);
  const currentExperiment = savedExperiments.find(
    (experimentChunk) => experimentChunk.split(AB_TESTS_COOKIE_ASSIGNATOR)[0] === experimentName,
  );

  return currentExperiment?.split(AB_TESTS_COOKIE_ASSIGNATOR)[1] as FeatureFlagValue;
}

function setVariantInCookie(experimentName: string, newValue: string) {
  const experimentsFromCookie = getCookie(AB_TESTS_COOKIE_NAME);

  const savedExperiments = experimentsFromCookie?.toString().split(AB_TESTS_COOKIE_SEPARATOR);
  const currentExperiment = savedExperiments?.find(
    (experimentChunk) => experimentChunk.split(AB_TESTS_COOKIE_ASSIGNATOR)[0] === experimentName,
  );

  // If this experiment is new and doesn't exist in the cookies, add it and return
  if (currentExperiment == null || savedExperiments == null) {
    const newCookieValue = [
      ...(savedExperiments ?? []),
      `${experimentName}${AB_TESTS_COOKIE_ASSIGNATOR}${newValue}`,
    ].join(AB_TESTS_COOKIE_SEPARATOR);
    setCookie(AB_TESTS_COOKIE_NAME, newCookieValue);
    return;
  }

  let updatedExperiments: string[] = [];

  for (const experimentChunk of savedExperiments) {
    const [name] = experimentChunk.split(AB_TESTS_COOKIE_ASSIGNATOR);
    if (name === experimentName) {
      updatedExperiments = [...updatedExperiments, `${name}${AB_TESTS_COOKIE_ASSIGNATOR}${newValue}`];
    } else {
      updatedExperiments = [...updatedExperiments, experimentChunk];
    }
  }

  setCookie(AB_TESTS_COOKIE_NAME, updatedExperiments.join(AB_TESTS_COOKIE_SEPARATOR), { maxAge: AB_TEST_MAX_AGE });
}

export function useActivateAbTestCallback(config: FeatureToggleConfig) {
  const cookiesFromServer = useAbTestCookieValue();

  if (!isFeatureFlagWithExperiments(config)) {
    return;
  }

  return (experimentName: string) => {
    const currentExperiment = config.experiments.find((experiment) => experiment.name === experimentName);
    const cookieValue = getVariantFromCookie(experimentName, cookiesFromServer);

    const value = cookieValue ?? getActiveVariantForExperiment(currentExperiment!);

    if (cookieValue == null || cookieValue !== value) {
      console.log('setting cookie', { value, cookieValue, cookiesFromServer });
      setVariantInCookie(experimentName, value);
    }

    return value;
  };
}
