import { DeepMaybe, MaybeValue } from '@sortlist-frontend/utils';

import { useMergedFeatureFlagConfig } from './FeatureFlagsProvider';
import {
  AvailableFeatureNames,
  AvailableFeatureToggles,
  FeatureFlagValue,
  FeatureToggleConfig,
  isFeatureFlagWithVariants,
} from './types';
import { useActivateAbTestCallback } from './useActivateAbTestCallback';

interface Config<T> {
  abTest?: T;
}

export function useIsFeatureActive<
  T extends AvailableFeatureNames,
  F extends AvailableFeatureToggles[T],
  V extends DeepMaybe<F, ['variants', number]>,
  E extends DeepMaybe<F, ['experiments', number, 'name']>,
>(
  featureName: T,
  /**
   * Options:
   * - abTest: if true, activates the ab test on hydration/mount. Defaults to undefined
   */
  config: Config<E> = { abTest: undefined },
): {
  /**
   * boolean: true or false if the feature is enabled in any variant excluding control
   */
  isActive: boolean;
  /**
   * string: returns the currently activated variant including control
   */
  variant?: V;
  /**
   * Callback to activate the experiment programmatically. Automatically activates the assigned experiment, but
   * if you need to filter to a specific experiment you can provide the name. Useful if you have multiple experiments
   * running under the same feature flag e.g. mobile/desktop variants
   * @param experimentName
   * @returns void
   */
  activateAbTest: (experimentName: E) => void;
} {
  const featureFlagConfig = useMergedFeatureFlagConfig();
  const currentConfig = featureFlagConfig[featureName];
  const activate = useActivateAbTestCallback(currentConfig);

  const activateAbTest = (experimentName: E) => {
    const activeVariant = activate?.(experimentName as unknown as string);

    if (activeVariant != null) {
      console.log('activating automatically', { activeVariant });
      currentConfig.defaultValue = activeVariant as typeof currentConfig.defaultValue;
    }
  };

  if (config.abTest != null) {
    activateAbTest(config.abTest);
  }

  // A feature is considered active if it's either true, or something other than
  // control is enabled (AB test). All controls end with -c according to conventions
  const isActive = isValueActive(currentConfig.defaultValue);
  const variant = getVariant(currentConfig);

  return {
    isActive,
    variant: variant as V,
    activateAbTest,
  };
}

function getVariant(config: FeatureToggleConfig) {
  const value = config.defaultValue;
  if (typeof value === 'string' && isFeatureFlagWithVariants(config)) {
    return value as keyof typeof config.variants;
  }
}

function isValueActive(v: FeatureFlagValue | boolean) {
  if (typeof v === 'string') {
    return !v.endsWith('-c') && v !== 'control';
  }
  return v;
}
