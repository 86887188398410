import { MenuRounded, SearchRounded } from '@mui/icons-material';
import { Logo } from '@sortlist-frontend/design-system';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { FC, useEffect, useState } from 'react';

import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';
import { CompareMenu } from '_components/Comparator/Menu';
import { usePublicAppContext } from '_core/context/public-app-context';

import type { BriefingDialogProps } from './BriefingDialog';
import type { ExploreMenuProps } from './ExploreMenu';
import type { MainMenuProps } from './MainMenu';
import type { SearchMenuProps } from './SearchMenu';

const BriefingDialog = dynamic<BriefingDialogProps>(
  () => import('./BriefingDialog').then((mod) => mod.BriefingDialog),
  {
    ssr: false,
  },
);

const ExploreMenu = dynamic<ExploreMenuProps>(() => import('./ExploreMenu').then((mod) => mod.ExploreMenu), {
  ssr: false,
});

const SearchMenu = dynamic<SearchMenuProps>(() => import('./SearchMenu').then((mod) => mod.SearchMenu), {
  ssr: false,
});

const MainMenu = dynamic<MainMenuProps>(() => import('./MainMenu').then((mod) => mod.MainMenu), {
  ssr: false,
});

type Props = {
  className?: string;
};

export const MobileNav: FC<Props> = (props) => {
  const { className } = props;
  const [menu, setMenu] = useState<boolean>(false);
  const [cta, setCta] = useState('');
  const [openBriefingDialog, setOpenBriefingDialog] = useState<boolean>(false);
  const [exploreMenuOpen, setExploreMenuOpen] = useState<boolean>(false);
  const [searchMenuOpen, setSearchMenuOpen] = useState<boolean>(false);
  const [showIntercom, setShowIntercom] = useState<boolean>(true);
  const { domainInfo, locale } = usePublicAppContext();

  const toggleSearch = () => {
    setSearchMenuOpen(!searchMenuOpen);
  };

  const toggleMenu = () => {
    setMenu((prev) => !prev);
    setExploreMenuOpen(false);
    setExploreMenuOpen(false);
    setShowIntercom((prev) => !prev);

    if (window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: showIntercom,
      });
    }
  };

  useEffect(() => {
    if (cta) {
      setOpenBriefingDialog(true);
    } else {
      setOpenBriefingDialog(false);
    }
  }, [cta]);

  return (
    <div className={clsx(className, 'height-100')}>
      <div data-testid="mobile-nav" className="layout-fill layout-row layout-align-space-between-center">
        <div className="flex layout-row layout-align-start-center">
          <button
            onClick={toggleMenu}
            aria-label="about"
            className="bg-neutral-100 layout-column layout-align-center-center text-secondary-500 border-none p-0 m-0"
            id="toggle-menu-btn">
            <MenuRounded className="text-secondary-500" style={{ fontSize: 24 }} />
          </button>
        </div>
        <div className={'layout-column layout-align-center-center'}>
          <Obfuscate
            className="layout-column layout-align-center-center"
            href={domainInfo?.getLocalizedUrl(locale) as string}
            obfuscate={true}>
            <Logo type="monogram" height={28} />
          </Obfuscate>
        </div>
        <div className="flex layout-row layout-align-end-center">
          <CompareMenu />
          <div className="px-8">
            <button
              className="bg-neutral-100 layout-column layout-align-center-center text-secondary-500 border-none p-0 m-0"
              onClick={toggleSearch}
              aria-label="search">
              <SearchRounded className="text-secondary-500" style={{ fontSize: 24 }} />
            </button>
          </div>
        </div>
      </div>

      <MainMenu opened={menu} toggleMenu={toggleMenu} setCta={setCta} setExploreMenuOpen={setExploreMenuOpen} />

      <ExploreMenu
        opened={exploreMenuOpen}
        toggleMenu={toggleMenu}
        closeExploreMenu={() => setExploreMenuOpen(false)}
      />

      <SearchMenu opened={searchMenuOpen} toggleMenu={toggleSearch} />

      <BriefingDialog
        cta={cta}
        opened={openBriefingDialog}
        closeBriefingDialog={() => {
          setCta('');
        }}
      />
    </div>
  );
};
