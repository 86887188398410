import { publicRuntimeSchema, serverRuntimeSchema } from '../../../env/schema/env.schema';

type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
  : Lowercase<S>;

type WithoutPrefix<T> = T extends `NEXT_PUBLIC_${infer P}` ? P : never;

type InputType = WithoutPrefix<keyof typeof publicRuntimeSchema>;
type InputTypeServer = keyof typeof serverRuntimeSchema;

interface Config {
  publicEnv: Record<CamelCase<InputType>, string | undefined>;
  serverEnv: Record<CamelCase<InputTypeServer>, string | undefined>;
}

export function useConfig(): Config {
  return {
    publicEnv: {
      andromedaApiUrlManager: process.env.NEXT_PUBLIC_ANDROMEDA_API_URL_MANAGER,
      andromedaApiUrlAgency: process.env.NEXT_PUBLIC_ANDROMEDA_API_URL_AGENCY,
      reportingApiUrlAgency: process.env.NEXT_PUBLIC_REPORTING_API_URL_AGENCY,
      publicApiBaseUrlAgency: process.env.NEXT_PUBLIC_PUBLIC_API_BASE_URL_AGENCY,
      andromedaApiUrlPublic: process.env.NEXT_PUBLIC_ANDROMEDA_API_URL_PUBLIC,
      publicApiBaseUrlCloudflare: process.env.NEXT_PUBLIC_PUBLIC_API_BASE_URL_CLOUDFLARE,
      publicApiBaseUrlInternal: process.env.NEXT_PUBLIC_PUBLIC_API_BASE_URL_INTERNAL,
      clearbitApiBaseUrl: process.env.NEXT_PUBLIC_CLEARBIT_API_BASE_URL,
      s3SitemapsUrl: process.env.NEXT_PUBLIC_S3_SITEMAPS_URL,
      coreApiUrlCloudflare: process.env.NEXT_PUBLIC_CORE_API_URL_CLOUDFLARE,
      coreApiUrlInternal: process.env.NEXT_PUBLIC_CORE_API_URL_INTERNAL,
      queryApiBaseUrl: process.env.NEXT_PUBLIC_QUERY_API_BASE_URL,
      apiBaseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
      publicLegacyAppUrlInternal: process.env.NEXT_PUBLIC_PUBLIC_LEGACY_APP_URL_INTERNAL,
      leadforensicsId: process.env.NEXT_PUBLIC_LEADFORENSICS_ID,
      agencyAppUrl: process.env.NEXT_PUBLIC_AGENCY_APP_URL,
      clientAppUrl: process.env.NEXT_PUBLIC_CLIENT_APP_URL,
      managerAppUrl: process.env.NEXT_PUBLIC_MANAGER_APP_URL,
      legacyManagerAppUrl: process.env.NEXT_PUBLIC_LEGACY_MANAGER_APP_URL,
      slApiBaseUrl: process.env.NEXT_PUBLIC_SL_API_BASE_URL,
      slGraphqlBaseUrl: process.env.NEXT_PUBLIC_SL_GRAPHQL_BASE_URL,
      slMailerBaseUrl: process.env.NEXT_PUBLIC_SL_MAILER_BASE_URL,
      marketingInsightsUrl: process.env.NEXT_PUBLIC_MARKETING_INSIGHTS_URL,
      legacyAppUrl: process.env.NEXT_PUBLIC_LEGACY_APP_URL,
      appUrl: process.env.NEXT_PUBLIC_APP_URL,
      appFrUrl: process.env.NEXT_PUBLIC_APP_FR_URL,
      appNlUrl: process.env.NEXT_PUBLIC_APP_NL_URL,
      appBeUrl: process.env.NEXT_PUBLIC_APP_BE_URL,
      appItUrl: process.env.NEXT_PUBLIC_APP_IT_URL,
      appEsUrl: process.env.NEXT_PUBLIC_APP_ES_URL,
      appDeUrl: process.env.NEXT_PUBLIC_APP_DE_URL,
      appGbUrl: process.env.NEXT_PUBLIC_APP_GB_URL,
      appChUrl: process.env.NEXT_PUBLIC_APP_CH_URL,
      appCaUrl: process.env.NEXT_PUBLIC_APP_CA_URL,
      appUsUrl: process.env.NEXT_PUBLIC_APP_US_URL,
      segmentKey: process.env.NEXT_PUBLIC_SEGMENT_KEY,
      googlePlaceApiKey: process.env.NEXT_PUBLIC_GOOGLE_PLACE_API_KEY,
      segmentProxy: process.env.NEXT_PUBLIC_SEGMENT_PROXY,
      s3Bucket: process.env.NEXT_PUBLIC_S3_BUCKET,
      recurlySubdomain: process.env.NEXT_PUBLIC_RECURLY_SUBDOMAIN,
      streamApiKey: process.env.NEXT_PUBLIC_STREAM_API_KEY,
      localImageStrategy: process.env.NEXT_PUBLIC_LOCAL_IMAGE_STRATEGY,
      calendlyEnv: process.env.NEXT_PUBLIC_CALENDLY_ENV,
      sentryEnv: process.env.NEXT_PUBLIC_SENTRY_ENV,
      managerSentryDsn: process.env.NEXT_PUBLIC_MANAGER_SENTRY_DSN,
      agencySentryDsn: process.env.NEXT_PUBLIC_AGENCY_SENTRY_DSN,
      clientSentryDsn: process.env.NEXT_PUBLIC_CLIENT_SENTRY_DSN,
      publicSentryDsn: process.env.NEXT_PUBLIC_PUBLIC_SENTRY_DSN,
    },
    serverEnv: {
      integrationAppWorkspaceKey: process.env.INTEGRATION_APP_WORKSPACE_KEY,
      integrationAppWorkspaceToken: process.env.INTEGRATION_APP_WORKSPACE_TOKEN,
      projectLobbySecret: process.env.PROJECT_LOBBY_SECRET,
      projectCreationSecret: process.env.PROJECT_CREATION_SECRET,
      sentryIgnoreApiResolutionError: process.env.SENTRY_IGNORE_API_RESOLUTION_ERROR,
      enableCompression: process.env.ENABLE_COMPRESSION,
      nextSentryDryRun: process.env.NEXT_SENTRY_DRY_RUN,
      nextDisableSentry: process.env.NEXT_DISABLE_SENTRY,
      databaseUrl: process.env.DATABASE_URL,
      auth0ClientId: process.env.AUTH0_CLIENT_ID,
      auth0IssuerBaseUrl: process.env.AUTH0_ISSUER_BASE_URL,
      auth0ClientSecret: process.env.AUTH0_CLIENT_SECRET,
      auth0Audience: process.env.AUTH0_AUDIENCE,
      auth0BaseUrl: process.env.AUTH0_BASE_URL,
      auth0Scope: process.env.AUTH0_SCOPE,
      auth0Secret: process.env.AUTH0_SECRET,
      appCacheDsn: process.env.APP_CACHE_DSN,
      jitsiAppId: process.env.JITSI_APP_ID,
    },
  };
}
