import { TFunction, useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';
import { FC, useState } from 'react';

import { SectionTitle } from './SectionTitle';
import * as S from './styles';

type SectionProps = {
  id: 'explore' | 'post-a-project' | 'advice';
  title: string;
  subtitle: string;
};

const getSectionTitles = (t: TFunction): SectionProps[] => {
  return [
    {
      id: 'explore',
      title: t('common:navbar.findAgencies.explore.title'),
      subtitle: t('common:navbar.findAgencies.explore.subtitle_V2'),
    },
    {
      id: 'post-a-project',
      title: t('common:navbar.findAgencies.postAProject.title'),
      subtitle: t('common:navbar.findAgencies.postAProject.subtitle_V2'),
    },
    {
      id: 'advice',
      title: t('common:navbar.findAgencies.getAdvice.title'),
      subtitle: t('common:navbar.findAgencies.getAdvice.subtitle_V2'),
    },
  ];
};

export type FindAgenciesMenuProps = {
  className?: string;
};

export const FindAgenciesMenu: FC<FindAgenciesMenuProps> = (props) => {
  const { className } = props;
  const [activePanel, setActivePanel] = useState<SectionProps['id']>('explore');
  const { t } = useTranslation(['common']);

  return (
    <S.Dropdown className={clsx(className, 'width-100 relative bg-neutral-100 shadow-4')} role="menu">
      <div className="container-lg px-0 py-24">
        <div className="layout-row">
          <div className="flex-30 px-16 border-right border-secondary-300">
            {getSectionTitles(t).map((section, key) => {
              return (
                <SectionTitle
                  key={key}
                  data-testid={`navbar-menu-${section.id}`}
                  title={section.title}
                  subtitle={section.subtitle}
                  active={section.id === activePanel}
                  onMouseEnter={() => {
                    setActivePanel(section.id);
                  }}
                />
              );
            })}
          </div>

          {activePanel === 'explore' ? <S.Explore className="fadeIn" /> : null}
          {activePanel === 'post-a-project' ? <S.PostAProject className="fadeIn" /> : null}
          {activePanel === 'advice' ? <S.GetAdvice className="fadeIn" /> : null}
        </div>
      </div>
    </S.Dropdown>
  );
};
