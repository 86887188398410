import { ReactElement } from 'react';
import { FieldError } from 'react-hook-form';

import { BROADER_SUGGESTIONS, RELATED_SUGGESTIONS } from '_core/repos/skillsGraphql/utils';
import exampleJsonBrief from '_data/api-examples/project-api/brief.json';
import exampleJsonInternal from '_data/api-examples/project-api/internal-notes.json';
import exampleJsonMember from '_data/api-examples/project-api/member.json';
import exampleJsonSchema from '_data/api-examples/schema-api/schema.json';
import { Business, Person } from '_types/public-api';

export type BriefType = typeof exampleJsonBrief;
export type BriefTypeAnswers = typeof exampleJsonBrief.data;
export type Member = typeof exampleJsonMember;
export type SchemaType = typeof exampleJsonSchema;
export type InternalNotesType = typeof exampleJsonInternal;

export type LocaleParams = {
  locale?: string;
};

export type NoteArgs = {
  userChoice: 'advisor_meeting' | 'publish' | 'draft';
};

export type LinkStoriesDetails = {
  metadata: {
    persons?: Person[] | undefined;
    business?: Business | undefined;
    has_video?: boolean;
    id?: string | null;
    thumbnailUrl?: string;
  } | null;
  anchor: string;
  url?: string;
  position: number;
};

export type KeyAccountUser = {
  id?: number | null;
  first_name: string;
  last_name: string | null;
  locale: string | null;
  phone: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  picture_url: string | null;
  email: string | null;
  job_title: string | null;
  reply_to?: string | null;
  meeting_link: string;
  oauth_id?: string | null;
  job_name?: string | null;
};

export enum BriefingScreenContent {
  STEPS = 'STEPS',
  CLOSING = 'CLOSING',
}

export type User = {
  email: string;
  sub: string;
  email_verified: boolean;
  name: string | null;
  firstName: string;
  lastName: string;
  picture: string | null;
  locale: string;
};

export type Service = {
  $skills: Skill[];
  active: boolean;
  agency_slug: string;
  budget: number[];
  description: string;
  expertise_id: number;
  position: number;
  skill_tags: {
    name: string;
    uuid: string;
  }[];
};

export type V3Expertise = {
  id: number;
  slug: string;
  title: string;
  title_en?: string;
  title_fr?: string;
  title_nl?: string;
  title_es?: string;
  title_de?: string;
  title_it?: string;
  name: string;
  name_en?: string;
  name_fr?: string;
  name_nl?: string;
  name_es?: string;
  name_de?: string;
  name_it?: string;
  category: string;
  created_at?: string;
  updated_at?: string;
  localized_slug: Record<string, string>;
};

export type Skill = {
  name: string;
  slug?: string;
  uuid?: string | null;
  created_at?: string;
  updated_at?: string;
};

export type Term = {
  name: string;
  label?: string[] | null;
  uuid?: string | null;
};

export type Language = {
  name: string;
  code: string;
};

export type Sector = {
  industryId: number;
  name: string;
  title?: string | null;
  slug?: string;
  created_at?: string;
  updated_at?: string;
};

export type GeoPolygon = ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;

export type Location = {
  placeId?: string;
  address?: string;
  country?: string | null;
  types?: string[];
  coordinates?: Coordinates;
  polygon?: GeoPolygon;
  iso31661?: string;
};

export type Coordinates = {
  type?: string;
  coordinates?: number[];
};

export type ModulePlans = 'free' | 'past_due' | 'plus' | 'value_based';

export type Modules = 'branding' | 'visibility' | 'lead' | 'intelligence';

export type NestedError<T> = { [k in keyof T]: FieldError };

export type FreeTextOption = {
  tag: string | number;
  value: string | ReactElement;
};

export type SkillSuggestionsType = typeof BROADER_SUGGESTIONS | typeof RELATED_SUGGESTIONS;

export type FileAttachment = {
  filename: string;
  storageUrl: string;
  id: string;
};

export type BriefingCompletionType = {
  bciScore: number;
  meetMinimalRequirement: boolean;
  subscores: {
    key: string;
    score: number;
    progress: number;
    total: number;
    missingMandatoryAnswers: number;
    improvements: {
      key: string;
      progress: number;
      total: number;
      metadata: {
        required: boolean;
      };
    }[];
  }[];
};

export type BriefingCompletionFormatted = {
  bciScore: number;
  improvementsKey: string[];
  meetMinimalRequirement: boolean;
  subscores: {
    [key: string]:
      | {
          key: string;
          score: number;
          progress: number;
          missingMandatoryAnswers: number;
          total: number;
          improvements: {
            [key: string]:
              | {
                  progress: number;
                  total: number;
                  required: boolean;
                }
              | undefined;
          };
        }
      | undefined;
  };
};

export type Opportunity = {
  appliedAt: string | null;
  applicationSentAt: string | null;
  meetingHappened: boolean;
  agency: {
    name: string;
    logo: string;
    rating: number;
    reviewsCount: number;
    slug: string;
  };
  archived: boolean;
  nominee: {
    confirmedAt: string | null;
    archived: boolean;
    pickedAt: string | null;
  };
};

export enum OnboardingFlowType {
  ASSISTED = 'ASSISTED',
  SELF_SERVED = 'SELF_SERVED',
  DISCARDED = 'DISCARDED',
}

export enum LobbyVersion {
  ASSISTED = 'assisted',
  PUBLISHED = 'published',
  READY = 'ready',
  DRAFT = 'draft',
}

export enum PhoneValidationStatus {
  PENDING = 'PENDING',
  VALID = 'VALID',
}

export type ProjectMember = {
  id: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  projectId: number;
  phone: string;
  phoneValidationStatus: PhoneValidationStatus;
  oauthId?: string;
  uuid: string;
  availabilities: {
    nodes: {
      startingAt: string;
    }[];
  };
};
