import styled from '@emotion/styled';

import { Explore as ExplorePanel } from './Explore';
import { GetAdvice as GetAdvicePanel } from './GetAdvice';
import { PostAProject as PostAProjectPanel } from './PostAProject';

export const Dropdown = styled.div`
  left: 0;
  z-index: 97;
  overflow: hidden;
`;

export const Panel = styled.div`
  &.fadeIn {
    animation: 0.2s ease-in-out fadeIn;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
`;

export const Explore = Panel.withComponent(ExplorePanel);
export const GetAdvice = Panel.withComponent(GetAdvicePanel);
export const PostAProject = Panel.withComponent(PostAProjectPanel);
