import { Logo, useMediaQuery } from '@sortlist-frontend/design-system';
import clsx from 'clsx';
import { ReactElement } from 'react';

import * as S from './styles';

export type MinimalHeaderProps = {
  isDark?: boolean;
  minimalHeaderChildren?: ReactElement;
  isFullWidth?: boolean;
};

export const MinimalHeader = (props: MinimalHeaderProps) => {
  const { isDark = false, minimalHeaderChildren, isFullWidth = false } = props;
  const { media } = useMediaQuery();

  return (
    <S.Nav
      className={clsx(
        isDark ? 'bg-secondary-900' : 'bg-neutral-100',
        minimalHeaderChildren != null && !media.xs ? 'layout-align-space-between-center' : 'layout-align-center-center',
        { 'layout-row': !isFullWidth },
        'px-16 text-center border border-secondary-300',
      )}>
      <div className={clsx({ 'container-lg': !isFullWidth }, 'layout-row layout-align-space-between-center')}>
        <Logo height={28} className="hide show-gt-xs" type="logotype" inversion={isDark ? 'positive' : 'negative'} />
        <Logo height={28} className="hide-gt-xs" type="monogram" inversion={isDark ? 'positive' : 'negative'} />
        {minimalHeaderChildren}
      </div>
    </S.Nav>
  );
};
