import styled from '@emotion/styled';
import { COLORS } from '@sortlist-frontend/design-system';

export const SubNavItem = styled.button`
  outline: none;
  border: none;
  background-color: ${COLORS.SECONDARY_100};

  &:hover {
    span,
    svg {
      color: ${COLORS.SECONDARY_900};
    }
    border-bottom: 3px solid ${COLORS.SECONDARY_900} !important;
  }
`;

export const SubNavMenu = styled.div`
  display: none;
  box-shadow:
    0 4px 2px -2px rgba(15, 13, 10, 0.1),
    0 0px 20px rgba(15, 13, 10, 0.08);

  &.slide-in-top-subnav {
    display: flex;
    animation-duration: 0.2s;
    animation-name: slideInTopSubnav;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    transform: translateY(-10%);
  }

  @keyframes slideInTopSubnav {
    0% {
      opacity: 0;
      transform: translateY(-10%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  a {
    &:hover {
      color: ${COLORS.SECONDARY_900};
    }
  }
`;
