import { ArrowForwardRounded } from '@mui/icons-material';
import { MenuItem } from '@sortlist-frontend/design-system';
import { FC, MouseEvent } from 'react';

import { MenuData } from '../utils';
import * as S from './styles';

export type MenuDropdownProps = {
  handleCloseMenu: (event: MouseEvent<HTMLDivElement>) => void;
  anchorEl: null | HTMLElement;
  links: MenuData[];
};

export const MenuDropdown: FC<MenuDropdownProps> = (props) => {
  const { anchorEl, handleCloseMenu, links } = props;
  const open = Boolean(anchorEl);

  return (
    <S.MainMenu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
      {links.map((link, index: number) => {
        const encoded = btoa(link.href);
        return (
          link.visible && (
            <MenuItem key={`${link.label}-${index}`}>
              <S.MenuButton
                size="sm"
                iconSize="lg"
                data-testid={link.dataTestid}
                className="small text-left flex py-12 px-16"
                rel={encoded}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  const decodedUrl = atob(event.currentTarget.getAttribute('rel') as string);
                  if (decodedUrl != null) {
                    window.location.href = link.href;
                  }
                }}
                label={link.label}
                buttonVariant="default"
                buttonStyle="secondary"
                iconRight={<ArrowForwardRounded style={{ fontSize: 16, opacity: 0.35 }} />}
              />
            </MenuItem>
          )
        );
      })}
    </S.MainMenu>
  );
};
