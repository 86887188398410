import styled from '@emotion/styled';
import { COLORS } from '@sortlist-frontend/design-system';

export const ProvidersLink = styled.a`
  &:hover,
  &.active {
    background-color: ${COLORS.PRIMARY_100};

    span {
      color: ${COLORS.PRIMARY_500};
    }

    .arrow-forward {
      display: block !important;
    }
  }
`;
