import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { FC, useEffect, useState } from 'react';

import { NormalBriefingProps } from '_components/Briefing/versions/NormalBriefing/types';
import { usePublicAppContext } from '_core/context/public-app-context';
import { Expertise } from '_core/repos/expertises.repo';

import * as S from './styles';

const NormalBriefing = dynamic<NormalBriefingProps>(
  () => import('_components/Briefing/versions/NormalBriefing').then((mod) => mod.NormalBriefing),
  { ssr: false },
);

type Props = {
  className?: string;
  expertises?: Expertise[];
};

export const ExpertiseList: FC<Props> = (props) => {
  const { expertises, className } = props;
  const [open, setOpen] = useState(false);
  const [clickedExpertise, setClickedExpertise] = useState(0);
  const { domainInfo, locale, briefingOptions } = usePublicAppContext();
  const currency = domainInfo?.getCurrency();
  const iso31661 = domainInfo?.getIso31661();

  const openBriefing = (event: React.MouseEvent<HTMLAnchorElement>, expertise: Expertise) => {
    event.preventDefault();
    setClickedExpertise(expertise.expertiseId);
  };

  // Next doesn't handle regional locales yet.. The only one we handle at the moment is nl-BE.
  // To make sure the project are posted correctly and handled by the right person, we need this trick.
  const regionalizedLocale = domainInfo?.getIso31661() === 'BE' && locale === 'nl' ? 'nl-BE' : locale;

  useEffect(() => {
    if (clickedExpertise) {
      setOpen(true);
    }
  }, [clickedExpertise]);

  return (
    <>
      <ul className={clsx('list-reset small', className)}>
        {expertises?.map((expertise, index: number) => {
          return (
            <li className="mb-8" key={`${index}-${expertise.slug}`}>
              <S.ExpertiseAnchor
                onClick={(event) => {
                  openBriefing(event, expertise);
                }}
                href="#"
                className="text-secondary-500">
                {expertise.name}
              </S.ExpertiseAnchor>
            </li>
          );
        })}
      </ul>
      {open && (
        <NormalBriefing
          page={briefingOptions?.page}
          // cta={cta} - @TODO get from Olivier
          address={briefingOptions?.address}
          placeId={briefingOptions?.placeId}
          expertise={clickedExpertise}
          locale={regionalizedLocale}
          currency={currency as string}
          iso31661={iso31661 as string}
          projectSkills={briefingOptions?.skillObjects}
          open={open}
          onClose={() => {
            setOpen(false);
            setClickedExpertise(0);
          }}
        />
      )}
    </>
  );
};
