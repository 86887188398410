import styled from '@emotion/styled';
import { Button, COLORS, Menu, RADIUSES, SHADOWS, TYPOGRAPHY } from '@sortlist-frontend/design-system';

export const MenuButton = styled(Button)`
  font-family: ${TYPOGRAPHY.SANS_SERIF};
`;

export const MainMenu = styled(Menu)`
  .MuiMenu-paper {
    border-radius: ${RADIUSES.md};
    box-shadow: ${SHADOWS.DEPTH_4.NEUTRAL_900};

    ul {
      border-radius: ${RADIUSES.md};
      padding: 0;

      li {
        border-bottom: 1px solid ${COLORS.SECONDARY_300};
        padding: 0;
        &:last-of-type {
          border-bottom: 0;
        }
      }
    }
  }
`;
