import { ComponentProps, ElementType } from 'react';

type ReferrerPolicy =
  | 'no-referrer'
  | 'no-referrer-when-downgrade'
  | 'origin'
  | 'origin-when-cross-origin'
  | 'same-origin'
  | 'strict-origin-when-cross-origin'
  | 'unsafe-url';

type Target = '_blank' | '_parent' | '_self' | '_top';

type Rel =
  | 'alternate'
  | 'author'
  | 'bookmark'
  | 'external'
  | 'help'
  | 'license'
  | 'next'
  | 'nofollow'
  | 'noreferrer'
  | 'noopener'
  | 'prev'
  | 'search'
  | 'tag'
  | string;

type TempLinkProps = {
  color?: string;
  id?: string;
  name?: string;
  disabled?: boolean;
  href?: string;
  obfuscate?: boolean;
  referrerPolicy?: ReferrerPolicy;
  target?: Target;
  rel?: Rel;
} & Partial<ComponentProps<'a'>>;

export type ObfuscateProps = {
  color?: string;
  id?: string;
  name?: string;
  disabled?: boolean;
  href: string;
  obfuscate?: boolean;
  referrerPolicy?: ReferrerPolicy;
  target?: Target;
  rel?: Rel;
  as?: ElementType;
} & Partial<ComponentProps<'a'>>;

export const Obfuscate = (obfuscateProps: ObfuscateProps) => {
  const {
    href,
    obfuscate,
    children,
    target = '_self',
    className = '',
    disabled = false,
    as: Tag = 'span',
    onClick,
    ...restProps
  } = obfuscateProps;
  const props: TempLinkProps = { ...restProps, disabled };

  if (obfuscate) {
    props.href = undefined;
    props.rel = btoa(href);
    props.onClick = (e) => {
      if (onClick != null) {
        onClick(e);
      }
      const base64Url = (e.currentTarget as HTMLElement).getAttribute('rel');
      if (base64Url != null) {
        const decodedUrl = atob(base64Url);
        window.open(decodedUrl, target);
      }
    };
  } else {
    props.href = href;
    if (target != null) {
      props.target = target;
      props.onClick = (e) => onClick != null && onClick(e);
    }
  }

  return obfuscate ? (
    <Tag className={`${className} cursor-pointer`} {...props}>
      {children}
    </Tag>
  ) : (
    <a className={`${className} cursor-pointer`} {...props}>
      {children}
    </a>
  );
};
