import clsx from 'clsx';
import { FC } from 'react';

import { getLocalizedUrl } from '_core/utils/public-links/getLocalizedUrl';
import { AnchorType } from '_types/public-api';

import * as S from './styles';

type Props = {
  links?: AnchorType[];
  className?: string;
};

export const ExpertiseList: FC<Props> = (props) => {
  const { links, className } = props;

  return (
    <ul className={clsx('list-reset small', className)}>
      {links?.map((levelTwo, key) => {
        const localizedLink = getLocalizedUrl(levelTwo);
        return (
          <li className="mb-8" key={key}>
            <S.Anchor href={localizedLink} className="text-secondary-500" obfuscate>
              {levelTwo.anchor}
            </S.Anchor>
          </li>
        );
      })}
    </ul>
  );
};
