import { ArrowForward } from '@mui/icons-material';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { splitArrayIntoTwoEqualParts } from '@sortlist-frontend/utils';
import clsx from 'clsx';
import { FC, useMemo, useState } from 'react';

import { usePublicAppContext } from '_core/context/public-app-context';
import { PublicApiRepo, useGetLinks } from '_core/repos/public-api.repo';
import { Levels } from '_core/utils/public-links/_types';
import { categoryFiltered } from '_core/utils/public-links/category-filtered';
import { createLevels } from '_core/utils/public-links/createLevels';
import { getLocalizedUrl } from '_core/utils/public-links/getLocalizedUrl';
import { services } from '_core/utils/public-links/services';
import { sortLevels } from '_core/utils/public-links/sort-levels';
import { AnchorType } from '_types/public-api';

import { ExpertiseList } from './ExpertiseList';
import * as S from './styles';

type Props = {
  className?: string;
};
export const Explore: FC<Props> = (props) => {
  const { className } = props;
  const { getUrl } = usePublicAppContext();
  const { t } = useTranslation(['common']);
  const { data: links } = useGetLinks(PublicApiRepo.publicApiNavBarUrl);

  const exploreLinks = useMemo(
    () => createLevels(links, services(links, 'navbar', categoryFiltered), sortLevels),
    [links],
  );

  const levelOneLinks = Object.keys((exploreLinks || {}) as Levels).sort((a, b) => a.localeCompare(b));
  const firstLink = levelOneLinks[0];
  const [activeProvider, setActiveProvider] = useState<string>(firstLink);

  const activeProvidersLinks =
    exploreLinks &&
    (exploreLinks as Levels)[activeProvider] &&
    (exploreLinks as Levels)[activeProvider]['null']['null'];

  const [activeProvFirstColumnLinks, activeProvSecondColumnLinks] = splitArrayIntoTwoEqualParts(
    activeProvidersLinks?.sort((a, b) => a.position - b.position), // sort again because firefox/safari seems to ignore the first sort. => hydration error
  );

  return (
    <div className={clsx(className, 'layout-row')}>
      <div className="flex-45 layout-column px-16">
        <header className="py-16 px-8">
          <p className="h6 bold">{t('common:navbar.findAgencies.explore.browseOurProviders')}</p>
          <p className="mt-8 small text-secondary-500">
            {t('common:navbar.findAgencies.explore.searchThroughADatabase')}
          </p>
        </header>
        <div className="py-16 px-8 layout-column layout-align-start-stretch">
          {levelOneLinks?.map((levelOne, key) => {
            return (
              <S.ProvidersLink
                key={key}
                data-testid={`navbar-explore-${key}-button`}
                href="#"
                className={clsx('p-12 text-secondary-900 rounded-sm display-inline-block', {
                  active: activeProvider === levelOne,
                })}
                onMouseEnter={() => {
                  setActiveProvider(levelOne);
                }}>
                <span className="layout-row layout-align-start-center">
                  <span className="medium pr-16 flex">{levelOne}</span>
                  <ArrowForward className="arrow-forward text-primary-300 hide" style={{ fontSize: 16 }} />
                </span>
              </S.ProvidersLink>
            );
          })}
        </div>
      </div>
      {activeProvidersLinks && activeProvidersLinks?.length > 0 && (
        <div className="flex layout-column px-16">
          <div
            data-testid={`navbar-explore-expertises`}
            className="bg-secondary-100 rounded-md layout-column px-8 flex-grow ">
            <div className="px-16 pt-16">
              <p className="h6 bold text-secondary-900">{activeProvider}</p>
            </div>
            <div className="px-8 pb-8 flex layout-column layout-align-start-stretch">
              <div className="layout-row mt-8">
                <div className="px-8 flex">
                  <ExpertiseList links={activeProvFirstColumnLinks as AnchorType[]} />
                </div>
                <div className="px-8 flex">
                  <ExpertiseList links={activeProvSecondColumnLinks as AnchorType[]} />
                </div>
              </div>
            </div>
            <div className="p-16">
              <p className="text-secondary-500 small">
                {t('common:navbar.findAgencies.explore.notWhatYouAreLookingFor')}
              </p>
              <a
                data-testid={`navbar-explore-browse-all-button`}
                href={getUrl('client.explore')}
                className="text-secondary-500 text-underline small">
                {t('common:navbar.findAgencies.explore.browseAllServices')}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
