import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material';
import { Button } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';
import { FC, MouseEvent, useState } from 'react';

import { usePublicAppContext } from '_core/context/public-app-context';

import { MenuDropdown } from '../MenuDropdown';
import { getClientsMenuData } from '../utils';

type GetClientsMenuProps = {
  handleMenuOpen: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

export const GetClientsMenu: FC<GetClientsMenuProps> = (props: GetClientsMenuProps) => {
  const { handleMenuOpen, className } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation(['common']);
  const { getUrl } = usePublicAppContext();
  const links = getClientsMenuData(t, getUrl);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    handleMenuOpen(event);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        data-testid="navbar-get-clients-dropdown"
        size="sm"
        iconSize="lg"
        className={clsx(className, 'small px-4')}
        onClick={handleOpen}
        label={t('common:navbar.menu.getClients')}
        buttonVariant="default"
        buttonStyle="secondary"
        animation="none"
        iconRight={
          anchorEl != null ? (
            <ArrowDropUpRounded className="text-secondary-300" />
          ) : (
            <ArrowDropDownRounded className="text-secondary-300" />
          )
        }
      />
      {anchorEl != null ? <MenuDropdown links={links} handleCloseMenu={handleCloseMenu} anchorEl={anchorEl} /> : null}
    </>
  );
};
