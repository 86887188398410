import { ArrowForward } from '@mui/icons-material';
import clsx from 'clsx';
import { FC } from 'react';

import * as S from './styles';

type Props = {
  title: string;
  subtitle: string;
  active: boolean;
  onMouseEnter: () => void;
};

export const SectionTitle: FC<Props> = (props) => {
  const { title, subtitle, active, onMouseEnter, ...restProps } = props;
  return (
    <S.SectionTitle
      className={clsx('p-16 rounded-lg cursor-pointer', { active: active })}
      onMouseEnter={onMouseEnter}
      {...restProps}>
      <div className="layout-row layout-align-space-between-center">
        <p className="h6 bold">{title}</p>
        <ArrowForward className="arrow-forward text-primary-300 hide" style={{ fontSize: 16 }} />
      </div>
      <p className="mt-8 small text-secondary-500">{subtitle}</p>
    </S.SectionTitle>
  );
};
