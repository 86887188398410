import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material';
import { Button, Logo, useOnClickOutside } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';
import { FC, MouseEvent, useRef, useState } from 'react';

import { BriefingButton } from '_components/Briefing/versions/NormalBriefing/button';
import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';
import { CompareMenu } from '_components/Comparator/Menu';
import { SubNav } from '_components/layout/SubNav';
import { usePublicAppContext } from '_core/context/public-app-context';

import { AccountMenu } from './AccountMenu';
import { GetClientsMenu } from './GetClientsMenu';
import { ResourcesMenu } from './ResourcesMenu';
import * as S from './styles';

type Props = {
  className?: string;
  showSearch?: boolean;
  showSubnav?: boolean;
};

export const DesktopNav: FC<Props> = (props) => {
  const { className, showSearch, showSubnav } = props;
  const { domainInfo, locale, briefingOptions } = usePublicAppContext();
  const { t } = useTranslation(['common']);

  const desktopNavRef = useRef(null);

  const [toggledFindAgencies, setToggledFindAgencies] = useState<boolean>(false);

  const handleOpenFindAgencies = () => {
    setToggledFindAgencies((prev) => !prev);
  };

  const handleCloseFindAgencies = (event: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    event.stopPropagation();
    setToggledFindAgencies(false);
  };

  useOnClickOutside(desktopNavRef, (event: any) => handleCloseFindAgencies(event));
  return (
    <div ref={desktopNavRef} className={clsx(className, 'layout-column')}>
      <S.NavBarWrapper className="relative width-100 layout-row layout-align-start-center bg-neutral-100 border-bottom border-secondary-300">
        <div data-testid="desktop-nav" className="container-lg layout-row layout-align-start-center">
          <div className="layout-row layout-align-start-center flex-none">
            <Obfuscate href={domainInfo?.getLocalizedUrl(locale) as string} obfuscate={true}>
              <Logo type="logotype" height={28} className="mr-16" />
            </Obfuscate>
            <Button
              data-testid="navbar-find-agencies"
              animation="none"
              size="sm"
              iconSize="lg"
              className="flex-none small px-4"
              onClick={handleOpenFindAgencies}
              label={t('common:navbar.menu.findAgencies_V2')}
              buttonVariant="default"
              buttonStyle="secondary"
              iconRight={
                toggledFindAgencies ? (
                  <ArrowDropUpRounded className="text-secondary-300" />
                ) : (
                  <ArrowDropDownRounded className="text-secondary-300" />
                )
              }
              id="find-agencies-btn"
            />

            <GetClientsMenu className="flex-none" handleMenuOpen={handleCloseFindAgencies} />
            <ResourcesMenu className="flex-none" handleMenuOpen={handleCloseFindAgencies} />
          </div>
          <div className="flex layout-row layout-align-end-center">
            {showSearch && (
              <div className="px-8 layout-row flex layout-align-end-center">
                <S.SearchCta
                  cta="hero"
                  page="search-landing"
                  element="search-landing"
                  showIndustry={false}
                  version="navbarDesktop"
                  keywordSelectorPlaceholder={t('common:navbar.menu.whatService')}
                  className="flex"
                />
              </div>
            )}
            <CompareMenu />
            <div className="px-8">
              <BriefingButton
                page={briefingOptions?.page}
                cta="nav-need"
                dataTestid="briefing-button-nav-need"
                size="sm"
                buttonStyle="primary"
                buttonVariant="raised"
                className="m-0"
                label={t('common:navbar.menu.postAProject')}
                truncate={false}
              />
            </div>
            <div className="pl-8">
              <AccountMenu />
            </div>
          </div>
        </div>
      </S.NavBarWrapper>
      {toggledFindAgencies ? <S.FindAgenciesMenu className="slide-in-top" /> : showSubnav ? <SubNav /> : null}
    </div>
  );
};
