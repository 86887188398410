import styled from '@emotion/styled';

export const NavbarExpertiseList = styled.div`
  max-height: 30rem;
  overflow: hidden;

  &:hover {
    overflow: auto;
  }
`;
