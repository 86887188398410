import styled from '@emotion/styled';
import { Divider } from '@sortlist-frontend/design-system';
import dynamic from 'next/dynamic';

import { NAVBAR_HEIGHT } from '_components/layout/constants';
import { SearchCtaProps } from '_components/search-agencies/SearchCta';

import { FindAgenciesMenu as FindAgenciesMenuComponent } from './FindAgenciesMenu';

const SearchCtaComponent = dynamic<SearchCtaProps>(
  () => import('_components/search-agencies/SearchCta').then((mod) => mod.SearchCta),
  {
    ssr: false,
  },
);

export const NavBarWrapper = styled.div`
  z-index: 98;
  min-height: ${NAVBAR_HEIGHT};
`;

export const FindAgenciesMenu = styled(FindAgenciesMenuComponent)`
  display: none;

  &.slide-in-top {
    display: block;
    animation-duration: 0.2s;
    animation-name: slideInTop;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    transform: translateY(-100%);
  }

  @keyframes slideInTop {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const SearchCta = styled(SearchCtaComponent)`
  max-width: 20rem !important;
`;
