import styled from '@emotion/styled';

export const Nav = styled.nav`
  min-height: 4rem;
  z-index: 99;
  position: sticky;
`;

export const Brand = styled.img`
  height: 1.875rem;
`;
